<template>
  <div class="demo">
    <div class="enterDetalis_title">满意度评分</div>
    <div class="flex flex-end">
      <div class="flex flex-1 mdName">门店</div>
      <div class="flex flex-column exter_PMS">
        <span class="btn">满意度</span>
        <div class="flex enter_btn flex-center">
          <div
            class="asc"
            :class="{ active: condition == 'contrast_top' }"
            @click="getContrast('contrast_top')"
          ></div>
          <div
            class="desc"
            :class="{ active: condition == 'contrast_down' }"
            @click="getContrast('contrast_down')"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="occupancyInfo.length" class="enter_panel flex-1">
      <div
        v-for="(info, index) in occupancyInfo"
        :key="index"
        class="flex enter_cell listHover"
      >
        <div class="exter_shop flex-1 text-center">
          {{ showName(info.hotelName) }}
        </div>
        <div class="exter_PMS">
          {{ info.grade }}
        </div>
      </div>
    </div>
    <div v-else class="noData flex flex-center align-center">
      暂无 满意度评分
    </div>
  </div>
</template>
<script>
import { GET_OCCUPANCY_INFO } from "@/api";
import { showName } from "@/api/common";
export default {
  data() {
    return {
      condition: "contrast_down",
      order: "asc",
      // 出租率情况
      occupancyInfo: [],
    };
  },
  created() {
    this.showPingFen();
    this.getOccupancyInfo();
    this.gradeInter = null;
    this.gradeInter = setInterval(() => {
      this.getOccupancyInfo();
    }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.gradeInter);
  },
  methods: {
    showPingFen() {
      let n1 = Math.random() * 0.4;
      let num = (n1 + 4.5).toFixed(1);
      return num;
    },
    showName(val) {
      // if (this.$route.meta.title == "万润看板") return val;
      // else
       return showName(val);
    },
    getContrast(val) {
      if (val == this.condition) {
        return;
      }
      this.condition = val;
      this.showlist(val);
    },
    showlist(val) {
      if (val == "contrast_top") {
        this.occupancyInfo.sort((a, b) => {
          return a.grade - b.grade;
        });
      } else if (val == "contrast_down") {
        this.occupancyInfo.sort((a, b) => {
          return b.grade - a.grade;
        });
      }
    },
    // 获取出租率情况
    async getOccupancyInfo(
      data = { condition: "pmsOccupancyRate", order: "asc" }
    ) {
      const res = await GET_OCCUPANCY_INFO(data);
      this.occupancyInfo = [];
      res.data.data.forEach((v) => {
        this.occupancyInfo.push({
          hotelName: v.hotelName,
          hotelId: v.hotelId,
          grade: this.showPingFen(),
        });
      });
      this.showlist(this.condition);
      // this.occupancyInfo = res.data.data;
      // this.condition = data.condition;
      // this.order = data.order;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/listAll.scss";
.aas {
  border-radius: 0.5vh;
}
</style>